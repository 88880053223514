<!--
 * @Author: gaojingran
 * @Date: 2021-05-24 15:25:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 11:40:21
 * @Description: 新增单价
-->

<template>
  <a-modal
    :width="680"
    :title="$t('companyInfo.title_new_price')"
    :maskClosable="false"
    :visible="visible"
    :footer="null"
    @cancel="handleCancel"
  >
    <a-spin :spinning="loading">
      <a-form-model ref="priceForm" v-bind="form" :colon="false" :rules="rules" :model="formdata">
        <!-- 服务类型 -->
        <a-form-model-item prop="serviceType" :label="$t('priceList.service_type')">
          <a-select
            v-model="formdata.serviceType"
            @change="onSelectType"
            :placeholder="$t('priceList.service_type_holder')"
          >
            <a-select-option v-for="item in serviceType" :key="item.dictValue" :value="item.dictValue">
              {{ item.dictLabel }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 行业领域 -->
        <a-form-model-item
          prop="field"
          :rules="disabledLang ? undefined : [validate.required]"
          :label="$t('priceList.industry_field')"
        >
          <a-cascader
            v-model="formdata.field"
            :options="industryList"
            :fieldNames="fielName"
            :disabled="disabledLang"
            :placeholder="$t('newPline.industry_field_holder')"
          ></a-cascader>
        </a-form-model-item>
        <!-- 文档类型 -->
        <a-form-model-item prop="documentType" :label="$t('priceList.doc_type')">
          <a-select v-model="formdata.documentType" :placeholder="$t('priceList.doc_type_holder')">
            <a-select-option v-for="item in docType" :key="item.dictValue" :value="item.dictValue">
              {{ item.dictLabel }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 源语言 -->
        <a-form-model-item
          prop="sourceCode"
          :key="formdata.serviceType"
          :label="$t('priceList.source_language')"
          :rules="disabledLang ? undefined : [validate.required]"
        >
          <a-select
            show-search
            v-model="formdata.sourceCode"
            :disabled="disabledLang"
            :filter-option="langFilterOption"
            :placeholder="$t('priceList.source_language_holder')"
          >
            <a-select-option v-for="item in langList" :key="item.code" :value="item.code">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 目标语言 -->
        <a-form-model-item
          prop="targetCode"
          :label="$t('priceList.target_language')"
          :rules="disabledLang ? undefined : [validate.required]"
        >
          <a-select
            show-search
            v-model="formdata.targetCode"
            :disabled="disabledLang"
            :filter-option="langFilterOption"
            :placeholder="$t('priceList.target_language_holder')"
          >
            <a-select-option v-for="item in langList" :key="item.code" :value="item.code">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 价格 -->
        <a-form-model-item prop="price" :label="$t('priceList.price')">
          <a-input v-model="formdata.price" :placeholder="$t('priceList.price_holder')"></a-input>
        </a-form-model-item>
        <!-- 单位 -->
        <a-form-model-item prop="unit" :label="$t('priceList.unit')">
          <a-select v-model="formdata.unit" :placeholder="$t('priceList.unit_holder')">
            <a-select-option v-for="item in unit" :key="item.dictValue" :value="item.dictValue">
              {{ item.dictLabel }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :wrapperCol="{ offset: 3 }">
          <a-button type="primary" class="submit-btn" @click="onSubmit" :loading="loading">
            {{ $t('submit') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'

const { validate } = window.$g

export default {
  name: 'AddPriceModal',
  props: {
    defaultInfo: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      validate,
      form: {
        labelCol: { span: 3 },
        wrapperCol: { span: 20 },
      },
      // options
      fielName: {
        value: 'dictValue',
        label: 'dictLabel',
        children: 'children',
      },
      formdata: {
        serviceType: undefined,
        field: undefined,
        documentType: undefined,
        sourceCode: undefined,
        targetCode: undefined,
        price: undefined,
        unit: undefined,
      },
      rules: {
        serviceType: [validate.required],
        documentType: [validate.required],
        price: [validate.float_3, validate.required],
        unit: [validate.required],
      },
    }
  },
  computed: {
    ...mapState('app', {
      langList: (state) => state.langList,
      industryList: (state) => state.industryList,
    }),

    disabledLang() {
      if (this.formdata.serviceType) {
        const type = this.formdata.serviceType.split('-')[0]
        return type === '2'
      }
      return false
    },

    serviceType() {
      return this.$store.getters['app/getDictByType']('SERVICE_TYPE')
    },

    docType() {
      return this.$store.getters['app/getDictByType']('DOC_TYPE')
    },

    unit() {
      return this.$store.getters['app/getDictByType']('SALE_UNIT')
    },
  },
  watch: {
    visible(val) {
      if (val) {
        const type = this.defaultInfo.serviceType?.split('-')[0] || ''
        const field1 = this.defaultInfo.field?.split('-')[0] + '-' + this.defaultInfo.field?.split('-')[1]
        this.formdata = {
          ...this.defaultInfo,
          field: type === '2' ? undefined : [field1 ?? 'error', this.defaultInfo.field],
          sourceCode: type === '2' ? undefined : this.defaultInfo.sourceCode,
          targetCode: type === '2' ? undefined : this.defaultInfo.targetCode,
        }
      } else {
        this.formdata = {
          serviceType: undefined,
          field: undefined,
          documentType: undefined,
          sourceCode: undefined,
          targetCode: undefined,
          price: undefined,
          unit: undefined,
        }
        this.$refs.priceForm.resetFields()
        this.$refs.priceForm.clearValidate()
      }
    },
  },
  methods: {
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    handleCancel() {
      if (this.loading) return
      this.$emit('update:visible', false)
    },

    onSelectType(val) {
      const type = val.split('-')[0]
      if (type === '2') {
        this.formdata.sourceCode = undefined
        this.formdata.targetCode = undefined
      }
    },

    onSubmit() {
      this.$refs.priceForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          try {
            await this.$http('newPrice', {
              ...this.formdata,
              field: this.formdata.field && this.formdata.field[this.formdata.field.length - 1],
            })
            this.loading = false
            this.$message.success(this.$t('priceList.create_success'))
            this.handleCancel()
            this.$emit('refresh')
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
  },
}
</script>
